import React from "react"
import classNames from "classnames"
import { ReactComponent as Branding } from "Static/assets/images/meltdown-flags-desktop.svg"

import "./CustomizerContainer.scss"

const CustomizerContainer = React.forwardRef((props, ref) => {
  const { children, classes, controls } = props;

  return (
    <div
      className={classNames("customizer-container pb-2 pb-md-0", classes)}
      ref={ref}
    >
      <div className="aspect-wrapper">
        <div className="content-wrapper">
          <div className="controls-wrapper">
            {controls}
          </div>
          <div className="content">
            {children}
          </div>
          <div className="branding-wrapper">
            <div className="branding">
              <Branding />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})


export default CustomizerContainer
