/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useRef, useLayoutEffect } from "react"
import classNames from "classnames"
import Button from "UI/Button/Button.jsx"
import domtoimage from 'dom-to-image'
import CustomizerContainer from "./CustomizerContainer"
import "./SignCustomizer.scss"

const SignCustomizer = props => {
  const {
    classes,
    placehoderText
  } = props

  const contentEditable = useRef(null)
  const containerDisplay = useRef(null)
  const containerExport = useRef(null)

  const [ text, setText ] = useState(placehoderText)
  const [ hasUserInput, setHasUserInput ] = useState(false)

  const handelState = () => {
    if(text.includes("placeholder-text")) setHasUserInput(false)
    if(text.includes("placeholder-text") === false && text.length !== 0) setHasUserInput(true)
  }

  const handleInput = () => {
    setText(contentEditable.current.innerHTML)
    handelState()
  }

  const handleFocus = () => {
    if(contentEditable.current.innerHTML.includes("placeholder-text")) {
      contentEditable.current.innerHTML = ""
      handelState()
    }
  }

  const handleBlur = () => {
    if(contentEditable.current.innerHTML === "") {
      contentEditable.current.innerHTML = placehoderText
      setText(placehoderText)
      handelState()
    }
  }

  const handleDownload = () => {
    domtoimage.toPng(containerExport.current)
    .then(dataUrl => {
      const link = document.createElement('a')
      link.download = 'Meltdown-Flag-Sign.png'
      link.href = dataUrl;
      link.click()
    })
    .catch(error => {
      console.error('oops, something went wrong! ', error)
    })
  }

  const controls = (() => (
    <Button
      text="Download"
      classes={classNames("btn btn-transparent btn-border-white btn-icon-right")}
      icon="download"
      onClick={handleDownload}
    />
  ))()

  const setLayout = () => {
    const setFontSize = (element, containerHeight) => {

      const fontSize = containerHeight * 0.13
      const lineHeight = fontSize * 0.9

      element.current.style.fontSize = `${fontSize}px`
      element.current.style.lineHeight = `${lineHeight}px`
    }

    setFontSize(containerDisplay, containerDisplay.current.offsetHeight)
    setFontSize(containerExport, 1080)
  }

  useLayoutEffect(() => {
    setLayout()
    handelState()

    if (typeof window !== `undefined`) {
      window.addEventListener('resize', setLayout)
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('resize', setLayout)
      }
    }
  })

  return (
    <>
      <div className={classNames("sign-customizer", hasUserInput && "has-user-input", classes)}>
        <CustomizerContainer
          controls={controls}
          ref={containerDisplay}
        >
          <div
            ref={contentEditable}
            className="content-editable"
            contentEditable
            spellCheck="false"
            onInput={handleInput}
            onClick={handleFocus}
            onTouchStart={handleInput}
            onBlur={handleBlur}
            onTouchEnd={handleBlur}
            dangerouslySetInnerHTML={{ __html: placehoderText }}
          />
        </CustomizerContainer>
      </div>
      <div className={classNames("sign-customizer export-element", classes)}>
        <CustomizerContainer
          ref={containerExport}
        >
          <div
            className="content-editable"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </CustomizerContainer>
      </div>
    </>
  )
}

export default SignCustomizer
