import React from "react"
import PropTypes from "prop-types"

import "./BasicText.scss"

const BasicText = (props) => {
  const { content } = props

  return (
    content.length && content.map(item => (
      <div key={`${item.title}-${Math.random() * 99999}`} className="basic-text-section col-12 col-md-6 mb-2 mb-md-0">
        <h2 className="mb-2 mb-md-0">{item.title}</h2>
        <div className="mt-2 pr-lg-4 text-half" dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    ))
  )
}

BasicText.propTypes = {
  content: PropTypes.instanceOf(Object)
}

BasicText.defaultProps = {
  content: {}
}

export default BasicText
