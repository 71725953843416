import React from "react"
import BasicTextHalf from "../BasicText/BasicTextHalf"
import SignCustomizer from "../../Containers/Layout/Customizer/SignCustomizer"

const CustomizerSectionV2 = ({content}) => {
  const { intro, signCustomizer } = content

  return (
    <section className="section customizer-section mt-2 pb-3 pb-md-5">
      <div className="container border-top">
        <div className="row pt-2">
          <BasicTextHalf content={intro} />
          <SignCustomizer
            classes="col-12 col-md-6" 
            placehoderText={signCustomizer.signPlaceholder}
          />
        </div>
      </div>
    </section>
  )
}

export default CustomizerSectionV2
