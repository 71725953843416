/* eslint-disable no-unused-vars */
import React from "react"
import Icon from "UI/Icon/Icon.jsx"
import Button from "UI/Button/Button.jsx"
import path from "path"
import FlagTeaser from "../../Containers/FlagTeaser/FlagTeaser"

import "./AllFlagsSection.scss"

const AllFlagsSection = ({content}) => {
const { headline, downloadAll, allCountries } = content

  return (
    <div className="section all-flags-section pb-3 pb-md-5">
      <div className="container border-top">
        <div className="row pt-2">
          <div className="col-12 col-md-6">
            <h2 className="mb-2 mb-md-0">
              {headline}
            </h2>
          </div>
          {
            allCountries.childrenCountriesJson.map(country =>  (
              <a 
                className="download-teaser col-6 col-lg-3 mt-2"
                key={country.id}
                download
                rel="noopener noreferrer"
                target="_blank"
                href={path.resolve(`./assets/Flags-${country.id}-mock.jpg`)}
              >
                <FlagTeaser 
                  image={country.flagPng}
                  slitScanHorizontal={country.slitScanHorizontal}
                />
                <div className="button-wrapper d-flex bg-black">
                  <span>Download</span>
                  <Button
                    classes="btn only-icon btn-border-white btn-icon-right"
                    icon="download"
                    realButton={false}
                  />
                </div>
                <span className="text-s">{country.title}</span>
              </a>
            )
          )
          }
          <a
            className="download-teaser download-all-link d-flex flex-column col-6 col-lg-3 mt-2"
            key="download-all-countries"
            rel="noopener noreferrer"
            target="_blank"
            href={path.resolve("./assets/All-Meltdown-Flags.zip")}
            download="All-Meltdown-Flags.zip"
          >
            <div className="download-box download-box d-flex flex-column justify-content-center align-items-center">
              {/* <Button
                classes="btn only-icon btn-border-white"
                icon="download"
                realButton={false}
              /> */}
              <div className="button-wrapper d-flex bg-black">
                <span>Download</span>
                <Button
                  classes="btn only-icon btn-border-white btn-icon-right"
                  icon="download"
                  realButton={false}
                />
              </div>
              <div className="text-s px-lg-2">{downloadAll}</div>
            </div>
            <span className="text-s">Download</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default AllFlagsSection
