import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Containers/Layout/Layout"
import SEO from "../components/seo"
import CustomizerSectionV2 from "../components/Sections/CustomizerSection/CustomizerSectionV2"
import AllFlagsSection from "../components/Sections/AllFlagsSection/AllFlagsSection"
import BasicText from "../components/Sections/BasicText/BasicText"

const actPage = ({data}) => {
  const {
    title,
    sections
  } = data.actPageJson

  return (
    <Layout>
      <SEO title={title} />
      <BasicText content={sections.actSection} />
      <BasicText content={sections.shopSection} />
      <CustomizerSectionV2 content={sections.costomizerSection} />
      <AllFlagsSection content={sections.allFlagsSection} />
      <BasicText content={sections.fontDownloadSection} image={sections.fontDownloadSection[0].image} />
    </Layout>
  )
}

export const query = graphql`
  {
    actPageJson {
      title
      sections {
        actSection {
          title
          text
        }
        shopSection {
          title
          text
        }
        allFlagsSection {
          downloadAll
          headline
          allCountries {
            childrenCountriesJson {
              id
              flagPng {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              title
              slitScanHorizontal
            }
          }
        }
        costomizerSection {
          intro {
            text
            title
          }
          signCustomizer {
            signPlaceholder
          }
        }
        fontDownloadSection {
          title
          text
          link
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default actPage
